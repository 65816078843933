<template>
  <div class="intention">
    <NavBar :list="navList" :router="false" name="意向金"></NavBar>
    <div class="public-box">
      <el-form :inline="true">
        <el-form-item>
          <MyInput v-model="formInline.purpose_no" placeholder="输入凭证号" class="offset margin-B15">
            <template slot="pre">凭证号:</template>
          </MyInput>
        </el-form-item>
        <el-form-item>
          <MyInput v-model="formInline.merchants_stall_name" placeholder="输入商铺号" class="offset margin-B15">
            <template slot="pre">意向商铺:</template>
          </MyInput>
        </el-form-item>
        <el-form-item>
          <MyInput v-model="formInline.user_name" placeholder="输入意向人姓名" class="offset margin-B15">
            <template slot="pre">意向人姓名:</template>
          </MyInput>
        </el-form-item>
        <el-form-item>
          <MyInput v-model="formInline.user_phone" placeholder="输入意向人联系电话" class="offset margin-B15">
            <template slot="pre">意向人联系电话:</template>
          </MyInput>
        </el-form-item>
        <el-form-item>
          <MyInput v-model="formInline.user_no" placeholder="输入意向人身份证号" class="offset margin-B15">
            <template slot="pre">意向人身份证号:</template>
          </MyInput>
        </el-form-item>
        <el-form-item>
          <el-date-picker class="offset margin-B15" v-model="searchDate" type="daterange" @change="searchDateChange"
            format="yyyy-MM-dd" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期"></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-select v-model="formInline.purpose_status" placeholder="状态" class="offset margin-B15"
            @change="currentChange(1)">
            <el-option v-for="(item, index) in selectstatus" :label="item" :key="index" :value="index"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <MyButton class="offset" title="搜索" @click="currentChange(1)">
            <template slot="preImage">
              <img src="@/unit/img/search.png" alt />
            </template>
          </MyButton>
        </el-form-item>
        <el-form-item>
          <MyButton class="offset" :accessId="40343" @click="dialogFormVisible = true">
            新增
            <template slot="preImage">
              <img src="@/unit/img/zj.png" alt />
            </template>
          </MyButton>
        </el-form-item>
        <el-form-item>
          <MyButton class="offset" title="导出" :accessId="1685" @click="intentionExport">
            <template slot="preImage">
              <img src="@/unit/img/down.png" alt />
            </template>
          </MyButton>
        </el-form-item>
      </el-form>
      <div class="public-table">
        <el-table :data="tableList" :header-cell-style="{
          'text-align': 'center',
          background: 'rgb(245, 245, 245)',
        }" :cell-style="{ 'text-align': 'center' }" height="95%">
          <el-table-column label="凭证号" width="230px" prop="purpose_no"></el-table-column>
          <el-table-column label="意向金(元)" prop="purpose_money"></el-table-column>
          <el-table-column label="楼栋" prop="merchants_room_name"></el-table-column>
          <el-table-column label="楼层" prop="merchants_floor_name"></el-table-column>
          <el-table-column label="意向商铺" show-overflow-tooltip prop="merchants_purpose_stall"></el-table-column>
          <el-table-column label="约定时间段" width="230px">
            <template slot-scope="scope">{{ scope.row.purpose_start_time }}~{{
              scope.row.purpose_end_time
            }}</template>
          </el-table-column>
          <el-table-column label="意向人姓名" prop="user_name"></el-table-column>
          <el-table-column label="意向人联系电话" prop="user_phone"></el-table-column>
          <el-table-column label="意向人身份证号" prop="user_no"></el-table-column>
          <el-table-column label="状态">
            <template #default="{ row }">
              <span>{{ selectstatus[row.purpose_status] }}</span>
            </template>
          </el-table-column>
          <el-table-column label="添加时间" width="230px" prop="create_at"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <pop tips="退款" :accessId="76626" popRight v-if="scope.row.purpose_status != -2 &&
                ((scope.row.pay_status == 10 &&
                  scope.row.purpose_status <= 0) ||
                  scope.row.purpose_status == 3)
                " @myclick="refund(scope.row)">
                <img src="../../../assets/img/icon/tuikuan.png" class="icon" alt />
              </pop>
              <pop tips="退款信息" :accessId="33391" popRight v-if="scope.row.pay_status == 10 &&
                [3, 4, 5].indexOf(scope.row.purpose_status) != -1
                " @myclick="refundMsg(scope.row)">
                <img src="../../../assets/img/icon/tuikuanxinxi.png" class="icon" alt />
              </pop>
              <pop tips="作废" :accessId="16663" popRight v-if="scope.row.purpose_status == 0"
                @myclick="cancellation(scope.row)">
                <img src="../../../assets/img/icon/zuofei.png" class="icon" alt />
              </pop>
              <pop tips="记录" :accessId="48499" popRight v-if="scope.row.purpose_status == 1 || scope.row.purpose_status == 2
                " @myclick="record(scope.row)">
                <img src="../../../assets/img/icon/jilu.png" class="icon" alt />
              </pop>
              <pop tips="缴费凭证" :accessId="69676" @myclick="voucher(scope.row)" v-if="scope.row.pay_status == 10">
                <img src="../../../assets/img/icon/pingzheng.png" class="icon" alt />
              </pop>
            </template>
          </el-table-column>
        </el-table>
        <div class="public-page" style="margin-top: 20px">
          <el-pagination next-text="下一页" prev-text="上一页" layout="total, prev, pager, next" :total="total"
            :page-size="formInline.pageSize" :current-page="formInline.pageNum"
            @current-change="currentChange"></el-pagination>
        </div>
      </div>
    </div>
    <el-dialog title="新增" :visible.sync="dialogFormVisible" width="880px" @close="close" :close-on-click-modal="false">
      <el-form :model="ruleform" :rules="rules" ref="ruleform" label-width="110px">
        <el-form-item label="意向人姓名" prop="user_name">
          <el-input v-model="ruleform.user_name" class="public-input" clearable placeholder="请输入意向人姓名"
            autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="user_phone">
          <el-input type="number" v-model="ruleform.user_phone" class="public-input" clearable placeholder="请输入联系电话"
            autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="身份证号" prop="user_no">
          <el-input v-model="ruleform.user_no" class="public-input" clearable placeholder="请输入身份证号"
            autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="意向金" prop="purpose_money">
          <el-input v-model="ruleform.purpose_money" class="public-input" clearable placeholder="请输入意向金"
            autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="预定时间" prop="purpose_start_time" ref="purpose_start_time">
          <el-date-picker v-model="presetTime" type="daterange" @change="changeDate" :picker-options="pickerOptions"
            format="yyyy-MM-dd" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期" />
        </el-form-item>
        <el-form-item label="楼栋" prop="merchants_room_id">
          <el-select v-model="ruleform.merchants_room_id" clearable class="public-input" placeholder="请选择楼栋"
            @change="roomChange">
            <el-option :label="item.name" :value="item.building_id" v-for="item in buildings"
              :key="item.building_id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="楼层">
          <el-select v-model="ruleform.merchants_floor_id" clearable class="public-input" placeholder="请选择楼层"
            @change="floorChange">
            <el-option :label="item.name" :value="item.building_floor_id" v-for="item in floors"
              :key="item.building_floor_id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="意向商铺">
          <el-select v-model="ruleform.merchants_stall_ids" clearable class="public-input" placeholder="请选择意向商铺">
            <el-option :label="item.merchants_stall_number" :value="item.merchants_stall_id"
              v-for="item in merchantsStall" :key="item.merchants_stall_id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="ruleform.purpose_remarks" class="public-input" clearable placeholder="请输入备注"
            autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="收款方式" prop="pay_type">
          <el-radio-group v-model="ruleform.pay_type">
            <el-radio :label="30">现金收款</el-radio>
            <el-radio :label="32">扫码收款</el-radio>
            <el-radio :label="33">刷卡支付</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <MyButton @click="dialogFormVisible = false" right>取 消</MyButton>
        <MyButton type="primary" @click="confirm">确 定</MyButton>
      </div>
    </el-dialog>

    <el-dialog title="意向金退款" :visible.sync="refundVisible" width="880px" @close="refundClose"
      :close-on-click-modal="false">
      <el-form :model="refundRuleform" :rules="refundRules" ref="refundRuleform" label-width="110px">
        <el-form-item label="退款账号" prop="refund_number">
          <el-input v-model="refundRuleform.refund_number" class="public-input" clearable placeholder="请输入退款账号"
            autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="退款户名" prop="refund_name">
          <el-input v-model="refundRuleform.refund_name" class="public-input" clearable placeholder="请输入退款户名"
            autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="退款金额" prop="refund_money">
          <el-input v-num v-model="refundRuleform.refund_money" class="public-input" placeholder="请输入退款金额"
            autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input type="textarea" class="public-input" v-model="refundRuleform.refund_remarks"
            placeholder=""></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <MyButton @click="refundVisible = false" right>取 消</MyButton>
        <MyButton type="primary" @click="refundConfirm">确 定</MyButton>
      </div>
    </el-dialog>
    <el-dialog title="记录" :visible.sync="recordVisible" width="880px" @close="recordClose" :close-on-click-modal="false">
      <el-table :data="recordList" :header-cell-style="{
        'text-align': 'center',
        background: 'rgb(245, 245, 245)',
      }" :cell-style="{ 'text-align': 'center' }">
        <el-table-column label="转用时间" prop="property_time"></el-table-column>
        <el-table-column label="使用人" prop="property_name"></el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <MyButton @click="recordVisible = false" right>关闭</MyButton>
      </div>
    </el-dialog>
    <el-dialog title="缴费凭证" :visible.sync="voucherVisible" width="1284px" @close="voucherClose"
      :close-on-click-modal="false">
      <voucher :data="{}"></voucher>
      <div slot="footer" class="dialog-footer">
        <MyButton @click="voucherVisible = false" right>关闭</MyButton>
        <MyButton @click="voucherVisible = false">打印</MyButton>
      </div>
    </el-dialog>
    <el-dialog title="退款信息" :visible.sync="refundMsgVisible" width="880px" @close="refundMsgClose"
      :close-on-click-modal="false">
      <el-table v-if="refundType == 20" :data="refundMsgList" :header-cell-style="{
        'text-align': 'center',
        background: 'rgb(245, 245, 245)',
      }" :cell-style="{ 'text-align': 'center' }">
        <el-table-column label="操作时间" prop="update_at"></el-table-column>
        <el-table-column label="退款状态" prop="status">
          <template #default="{ row }">
            <!-- <span v-if="scope.row.status == -2">全部</span>
            <span v-if="scope.row.status == 0">待使用</span>
            <span v-if="scope.row.status == 1">已使用</span>
            <span v-if="scope.row.status == 3">财务驳回</span>
            <span v-if="scope.row.status == 4">待退款</span>
            <span v-if="scope.row.status == 5">已退款</span>
            <span v-if="scope.row.status == -1">作废</span> -->
            <span>{{ selectstatus[row.status] }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作人" prop="worker_name"></el-table-column>
        <el-table-column label="驳回原因" prop="refund_desc">
          <template #default="{ row }">
            <span v-if="row.status == 5">/</span>
            <span v-else>{{ row.refund_desc }}</span>
          </template>
        </el-table-column>
        <el-table-column label="退款凭证" prop="refund_image">
          <template slot-scope="scope">
            <!-- <MyButton
              v-if="scope.row.status == 20"
              @click="checkVoucher(scope.row)"
              >查看</MyButton
            > -->
            <pop tips="退款凭证" v-if="scope.row.status == 20" @click="checkVoucher(scope.row)">
              <img src="../../../assets/img/icon/pingzheng.png" class="icon" alt />
            </pop>
          </template>
        </el-table-column>
      </el-table>
      <el-table v-if="refundType == 10" :data="refundMsgList" :header-cell-style="{
        'text-align': 'center',
        background: 'rgb(245, 245, 245)',
      }" :cell-style="{ 'text-align': 'center' }">
        <el-table-column label="操作时间" prop="update_at"></el-table-column>
        <el-table-column label="退款账号" prop="refund_number"></el-table-column>
        <el-table-column label="退款户名" prop="refund_name"></el-table-column>
        <el-table-column label="退款金额" prop="refund_money"></el-table-column>
        <el-table-column label="备注" prop="refund_remarks"></el-table-column>
        <el-table-column label="状态" prop="status">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 0">待审核</span>
            <span v-if="scope.row.status == 10">待打款</span>
            <span v-if="scope.row.status == 20">已完成</span>
            <span v-if="scope.row.status == 30">已驳回</span>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <MyButton @click="refundMsgVisible = false">关闭</MyButton>
      </div>
    </el-dialog>
    <el-dialog title="查看凭证" :visible.sync="examineVisible" width="880px" @close="examineClose"
      :close-on-click-modal="false">
      <div class="examineBox">
        <img :src="refundImage" alt />
      </div>
      <span slot="footer" class="dialog-footer">
        <MyButton title="关闭" @click="examineVisible = false"></MyButton>
      </span>
    </el-dialog>

    <!--  -->
    <!-- 扫码支付 -->
    <div class="mask" v-if="qrpay">
      <div class="qrclose" v-if="!isScan" @click="closeqrmask">
        <i class="el-icon-close"></i>
      </div>
      <div v-if="!isScan">
        <img src="../../../assets/gun.png" alt />
        <div>请出示付款码</div>
      </div>
      <div v-else>
        <i class="el-icon-loading"></i>
        <div>等待收款</div>
      </div>
    </div>
    <!-- 扫码枪 -->
    <input class="scanInput" type="text" ref="qrcode" v-model="qrcode" />
  </div>
</template>
<script>
import voucher from "@/components/voucher";

export default {
  name: "intention",
  components: {
    voucher,
  },
  data () {
    var maxValue = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入意向金"));
      }
      let data = this.refundRuleform;
      if (value > data.maxValue) {
        // data.refund_money = data.maxValue
        console.log(data.maxValue);
        return callback(new Error(`最大金额不能超过${data.maxValue}`));
      }
      callback();
    };
    return {
      navList: [
        {
          name: "意向金",
        },
      ],
      ruleform: {
        user_name: "",
        user_phone: "",
        user_no: "",
        purpose_money: "",
        purpose_start_time: "",
        purpose_end_time: "",
        merchants_stall_ids: [],
        purpose_remarks: "",
        pay_type: "",
        merchants_room_id: '',
        merchants_room_name: '',
        merchants_floor_id: '',
        merchants_floor_name: '',
      },
      rules: {
        user_name: [
          { required: true, message: "请输入意向人姓名", trigger: "blur" },
        ],
        user_phone: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
        ],
        user_no: [
          { required: true, message: "请输入身份证号码", trigger: "blur" },
        ],
        purpose_money: [
          { required: true, message: "请输入意向金", trigger: "blur" },
        ],
        purpose_start_time: [
          { required: true, message: "请选择预定时间", trigger: "change" },
        ],
        merchants_stall_ids: [
          { required: true, message: "请选择意向商铺", trigger: "change" },
        ],
        purpose_remarks: [
          { required: true, message: "请输入备注", trigger: "blur" },
        ],
        pay_type: [
          { required: true, message: "请选择收款方式", trigger: "change" },
        ],
        merchants_room_id: [
          { required: true, message: "请选择楼栋", trigger: "change" },
        ],
        merchants_floor_id: [
          { required: true, message: "请选择楼层", trigger: "change" },
        ],
      },
      dialogFormVisible: false,
      formInline: {
        pageNum: 1,
        pageSize: 10,
        purpose_start_time: "",
        purpose_end_time: "",
        purpose_no: "",
        merchants_stall_name: "",
        user_name: "",
        user_phone: "",
        user_no: "",
        purpose_status: "-3",
      },
      total: 0,
      tableList: [],
      merchantsStall: [],
      searchDate: [],
      presetTime: [],
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      refundVisible: false,
      refundRuleform: {
        purpose_id: "",
        refund_number: "",
        refund_name: "",
        refund_money: "",
        purpose_status: "",
        apply_desc: "意向金",
      },
      refundRules: {
        refund_number: [
          { required: true, message: "请输入退款账号", trigger: "blur" },
        ],
        refund_name: [
          { required: true, message: "请输入退款户名", trigger: "blur" },
        ],
        refund_money: [
          { required: true, trigger: "blur", validator: maxValue },
        ],
      },
      recordVisible: false,
      recordList: [],
      voucherVisible: false,
      refundMsgList: [],
      refundMsgVisible: false,
      examineVisible: false,
      refundImage: "",
      refundType: "", //退款前后信息
      qrpay: false,
      qrcode: "",
      isScan: false,
      timer: null,
      selectstatus: {
        "-3": "全部",
        0: "待使用",
        1: "已使用",
        3: "财务驳回",
        4: "已申请退款",
        5: "已完成退款",
        "-1": "作废",
        "-2": "已变更",
      },
      buildings: [],
      floors: []
    };
  },
  created () {
    this.getList();

    this.getBuild();
  },
  watch: {
    qrcode (val) {
      console.log(val);
      if (val.length > 0) {
        this.isScanCode = false;
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          const code = this.qrcode;
          this.scanPay(code);
          this.qrcode = "";
        }, 1000);
      }
    },
  },
  methods: {
    roomChange (e) {
      this.floors = []
      this.ruleform.merchants_floor_id = ""
      this.ruleform.merchants_floor_name = ""
      let data = this.buildings.find((item) => {
        return item.building_id == e
      })

      this.ruleform.merchants_room_name = data?.name || ''
      e && this.$request.HttpPost("/floor/list", {
        building_id: e,
        page: 1,
        pageSize: 999
      }).then((res) => {
        this.floors = res.data?.list || []
      })
    },
    floorChange (e) {
      let data = this.floors.find((item) => {
        return item.building_floor_id == e
      })
      this.ruleform.merchants_floor_name = data?.name || ''
    },
    intentionExport () {
      this.$request
        .HttpPost("/purpose/export", this.formInline, {
          responseType: "blob",
        })
        .then((res) => {
          let blob = new Blob([res]);
          let objectUrl = URL.createObjectURL(blob);
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = objectUrl;
          link.setAttribute("download", "意向金.xlsx");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link); // 下载完成移除元素
          window.URL.revokeObjectURL(objectUrl); // 释放掉blob对象
        });
    },
    cancellation (row) {
      this.$myconfirm("此操作将作废该意向金, 是否继续?", "提示").then(() => {
        this.$request
          .HttpPost("/purpose/edit", {
            purpose_id: row.purpose_id,
            purpose_status: -1,
          })
          .then(() => {
            this.$common.notifySuccess("意向金作废成功");
            this.getList();
          });
      });
    },
    examineClose () {
      this.refundImage = "";
    },
    checkVoucher (row) {
      if (!row.refund_image)
        return this.$message({ message: "暂无凭证!", type: "warning" });
      this.refundImage = row.refund_image;
      this.examineVisible = true;
    },
    refundMsgClose () {
      this.refundMsgList = [];
    },
    refundMsg (row) {
      this.$request
        .HttpGet("/bill_refund/getPurposeId", {
          id: row.purpose_id,
        })
        .then((res) => {
          if (res.data) {
            if (res.data.status == 10 || res.data.status == 0) {
              //退款钱
              this.refundType = 10;
            } else {
              this.refundType = 20;
            }
            this.refundMsgList = [res.data];
            this.refundMsgVisible = true;
          }
        });
    },
    voucherClose () { },
    voucher (row) {
      this.$request
        .HttpGet("/purpose/edit", {
          id: row.purpose_id,
        })
        .then((res) => {
          if (res.data.purpose_evidence) {
            window.open(
              process.env.VUE_APP_IMG_URL + res.data.purpose_evidence
            );
          } else {
            this.$common.notifyError("暂无凭证");
          }
        });
    },
    recordClose () {
      this.recordList = [];
    },
    record (row) {
      this.$request
        .HttpGet("/purpose/edit", {
          id: row.purpose_id,
        })
        .then((res) => {
          if (res.data) {
            this.recordList = [res.data];
            this.recordVisible = true;
          }
        });
    },
    refundClose () {
      // this.refundRuleform = {
      //   purpose_id: "",
      //   refund_number: "",
      //   refund_name: "",
      //   refund_money: "",
      // };
      this.refundRuleform = this.$options.data().refundRuleform
    },
    refundConfirm () {
      this.$refs.refundRuleform.validate((valid) => {
        if (valid) {
          this.$request
            .HttpPost("/purpose/edit", this.refundRuleform)
            .then((res) => {
              this.$common.notifySuccess("操作成功");
              this.getList();
              this.refundVisible = false;
            });
        }
      });
    },
    refund (item) {
      this.refundRuleform.purpose_id = item.purpose_id;
      this.refundRuleform.purpose_status = 4;
      this.refundRuleform.refund_money = item.purpose_money;
      this.refundRuleform.maxValue = item.purpose_money;
      this.refundVisible = true;
    },
    searchDateChange (date) {
      if (date) {
        this.formInline.purpose_start_time = date[0];
        this.formInline.purpose_end_time = date[1];
      } else {
        this.formInline.purpose_start_time = "";
        this.formInline.purpose_end_time = "";
      }
    },

    currentChange (page) {
      this.formInline.pageNum = page;
      this.getList();
    },
    getList () {
      this.$request.HttpGet("/purpose/list", this.formInline).then((res) => {
        this.tableList = res.data.list;
        this.total = res.data.total;
      });
    },
    changeDate (date) {
      if (date) {
        this.ruleform.purpose_start_time = date[0];
        this.ruleform.purpose_end_time = date[1];
        this.$refs.ruleform.validateField("purpose_start_time");
        this.getShop();
      } else {
        this.ruleform.purpose_start_time = "";
        this.ruleform.purpose_end_time = "";
        this.$refs.ruleform.clearValidate("purpose_start_time");
        this.merchantsStall = [];
      }
    },
    getBuild () {
      const param = {
        pageSize: 9999,
        pageNum: 1,
      };
      this.$request.HttpGet("/building/list", param).then((res) => {
        this.buildings = res.data?.list || []
      });
    },
    getShop () {
      this.$request
        .HttpGet("/merchants_stall/listAll", {
          endTime: this.ruleform.purpose_start_time,
        })
        .then((res) => {
          this.merchantsStall = res.data || [];
        });
    },
    close () {
      // this.ruleform = {
      //   user_name: "",
      //   user_phone: "",
      //   user_no: "",
      //   purpose_money: "",
      //   purpose_start_time: "",
      //   purpose_end_time: "",
      //   merchants_stall_ids: [],
      //   purpose_remarks: "",
      //   pay_type: "",
      // };
      this.ruleform = this.$options.data().ruleform
      this.presetTime = [];
      this.$refs.ruleform.clearValidate();
    },
    confirm () {
      this.$refs.ruleform.validate((valid) => {
        if (valid) {
          if (this.ruleform.pay_type == 32) {
            this.scanPayshow();
            return false;
          }
          this.$request.HttpPost("/purpose/add", this.ruleform).then((res) => {
            this.$common.notifySuccess(res.msg);
            this.dialogFormVisible = false;
            this.getList();
          });
        }
      });
    },
    // 扫码支付
    scanPayshow () {
      this.$refs.ruleform.validate((valid) => {
        this.qrpay = true;
        this.$nextTick(() => {
          this.$refs.qrcode.focus();
        });
      });
    },
    scanPay () {
      // this.$refs
      let params = {
        qrcode: this.qrcode,
        ...this.ruleform,
      };
      if (this.qrcode) {
        this.isScan = true;
        this.$request.HttpPost("/purpose/add", params).then((res) => {
          if (res.data) {
            if (res.data.payNo) {
              return this.getPayResult(res.data.payNo);
            }
            this.$common.notifyError("支付失败！");
            this.closeqrmask();
          }
        });
      }
      console.log(params);
      // this.getPayResult()
    },
    // 支付结果
    getPayResult (payNo) {
      let getTimer = setInterval(() => {
        this.$request
          .HttpPost("/bill_offline/barcodeGunSucceed", { payNo })
          .then((res) => {
            if (res.data) {
              if (res.data.code == 0) {
                clearInterval(getTimer);
                this.closeqrmask();
                this.getList();
                this.$message({ message: "支付成功", type: "success" });
                this.qrcode = "";
                this.$request
                  .HttpPost("/purpose/purposeReturn", {
                    orderNo: payNo,
                    status: 1,
                    ...this.ruleform,
                  })
                  .then(() => {
                    this.dialogFormVisible = false;
                  });
              } else if (res.data.code == -2) {
                clearInterval(getTimer);
                this.closeqrmask();
                this.$message({ message: "支付失败", type: "error" });
              }
            } else {
              clearInterval(getTimer);
              this.closeqrmask();
              this.$message({ message: "支付失败", type: "error" });
            }
          });
      }, 5000);
    },
    closeqrmask () {
      this.qrpay = false;
      this.qrcode = "";
      this.isScan = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.examineBox {
  width: 100%;

  img {
    width: 100%;
    max-height: 550px;
  }
}

.mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: #000000, $alpha: 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #ffff;
  font-size: 80px;
  z-index: 9999999;

  img {
    object-fit: contain;
  }

  .qrclose {
    position: absolute;
    right: 50px;
    top: 50px;
    cursor: pointer;
    font-size: 50px;
    text-align: center;
  }
}

.scanInput {
  z-index: -99;
}
</style>
